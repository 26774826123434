import(/* webpackMode: "eager" */ "/vercel/path0/apps/sdkode/src/app/sdkode.css");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/vercel/path0/apps/sdkode/src/components/layout/menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18_6wf2c2hasfh6evy7vlcf4agvgq/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-view-transitions@0.3.2_next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3_w4vs67pnh77jhylm3bipyya4eu/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/components/layout/theme-controller/theme-controller.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/packages/ui/src/providers/theme/theme-provider.tsx");
